.App {
  text-align: center;
  background-color: black;
  color: #000000; /* Add this line to change text color to white */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0e0e0e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

.text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@import url(https://fonts.googleapis.com/css?family=Nunito:400,700,300);

body {
  font-family: 'Nunito', sans-serif;
  background-color: #060e33;
}

.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.check {
  display: none;
}

.button {
  text-transform: uppercase;
  color: #ffffff;
  background-color: #030303;
  border: 2px solid #5927ff;
  display: inline-block;
  padding: 15px;
  padding-right: 50px;
  padding-left: 50px;
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 14px;
  transition: all .3s ease;
}

.button:before {
  width: 0%;
  position: absolute;
  left: 0;
  bottom: 3px;
  height: 5px;
  background-color: #5927ff;
  content: "";
}

.button:after {
  width: 100%;
  height: 0%;
  position: absolute;
  bottom: 3px;
  left: 0;
  background-color: #5927ff;
  content: "Download complete";
  overflow: hidden;
  text-align: center;
  line-height: 180px;
}

.button:hover {
  background-color: #5927ff;
  color: #060e33;
}

input[type=checkbox]:checked ~ .button {
  background: #000000;
  border-radius: 0px;
  border: none;
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 200px;
  padding-right: 200px;
  transition: all .5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

input[type=checkbox]:checked ~ .button:before {
  width: 100%;
  content: "";
  color: #000000;
  text-align: center;
  line-height: 70px;
  transition: all 3.5s linear;
}

input[type=checkbox]:checked ~ .button:after {
  height: calc(100% - 3px);
  color: #060e33;
  transition: all .3s ease-in-out;
  transition-delay: 3.5s;
}

.button .me {
  display: none;
}

.button .mo {
  transition: bottom .4s ease, opacity 1s ease;
  transition-delay: .3s;
  height: auto;
  width: auto;
  bottom: 0px;
  opacity: 1;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

